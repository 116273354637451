export default {
  namespaced: true,
  state: {
    documents: [],
  },
  getters: {
    getDocs: (state) => state.documents,
  },
  mutations: {
    addDocs: (state, data) => {
      state.documents = data;
    },
  },
  actions: {
    ADD_DOCUMENTS: ({ commit }, data) => {
      commit("addDocs", data);
    },
  },
};
