<template>
  <section class="content content___inner">
    <div class="news__main">
      <h2>Новости</h2>
      <div class="news__wrap">
        <ul id="accordion5" class="accordion5">
          <li
            v-for="(article, article_key) in news"
            :key="article_key"
            class="accordion-bg"
          >
            <div class="acc__wrap">
              <div class="news__img">
                <img
                  v-if="
                    article.images[0] && article.images[0]['serverFilename']
                  "
                  :src="`${SERVER_ADDRESS()}/static/${
                    article.images[0]['serverFilename']
                  }`"
                  alt=""
                />
                <img v-else src="../../assets/images/news-img.png" alt="" />
              </div>
              <div class="acc__inner">
                <div class="link3 open">
                  <p class="article__date">
                    {{ new Date(article.date).toLocaleDateString() }}
                  </p>
                  <div
                    :class="`news__title news__title__${article._id}`"
                    @click="toggleNewArticleDescription(article._id)"
                  >
                    {{ article.title }}
                    <svg
                      data-show="false"
                      width="20"
                      height="10"
                      viewBox="0 0 30 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0L15 15L30 0H0Z"></path>
                    </svg>
                  </div>

                  <div class="news__body" data-show="false">
                    <div>
                      {{ article.description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SERVER_ADDRESS } from "@/constants";

export default {
  name: "news",
  methods: {
    SERVER_ADDRESS() {
      return SERVER_ADDRESS;
    },
    ...mapActions("News", {
      addNews: "ADD_NEWS",
    }),
    toggleNewArticleDescription(id) {
      const articleBody = document.querySelector(`.news__title__${id}`)
        .parentNode.children[2];

      const articleArrow = document.querySelector(`.news__title__${id}`)
        .children[0];

      articleBody.dataset.show === "false"
        ? (articleBody.dataset.show = "true")
        : (articleBody.dataset.show = "false");

      articleArrow.dataset.show === "false"
        ? (articleArrow.dataset.show = "true")
        : (articleArrow.dataset.show = "false");
    },
  },
  computed: {
    ...mapGetters("News", {
      news: "getNews",
    }),
  },
};
</script>

<style scoped></style>
