export default {
  namespaced: true,
  state: {
    background: [],
  },
  getters: {
    getBackground: (state) => state.background,
  },
  mutations: {
    addBackground: (state, data) => {
      state.background = data;
      console.log(state);
    },
  },
  actions: {
    SET_BACKGROUND: ({ commit }, data) => {
      commit("addBackground", data);
    },
  },
};
