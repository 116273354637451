import Vue from "vue";
import Vuex from "vuex";
import News from "@/store/modules/News";
import Documents from "@/store/modules/Documents";
import Contacts from "@/store/modules/Contacts";
import Background from "@/store/modules/Background";

// import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    News,
    Documents,
    Contacts,
    Background,
  },
});
