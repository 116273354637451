<template>
  <section class="content content___inner">
    <div class="documents__main">
      <h2>ДОКУМЕНТЫ</h2>
      <form action="" class="form">
        <div class="search__title">Поиск</div>
        <input v-model="filterText" type="text" placeholder="" />
      </form>

      <div class="documents__container">
        <div
          v-for="document in filteredDocs"
          :key="document._id"
          class="document__wrap"
        >
          <a
            class="document__img"
            :href="`${
              document.documents[0]
                ? `${SERVER_ADDRESS()}/static/${
                    document.documents[0]['serverFilename']
                  }`
                : ''
            }`"
            :download="document.title"
            target="_blank"
          >
            <img :src="getFileIcon(document.documents[0])" alt="" />
          </a>
          <div class="document__inner">
            <a
              class="document__title"
              :href="`${
                document.documents[0]
                  ? `${SERVER_ADDRESS()}/static/${
                      document.documents[0]['serverFilename']
                    }`
                  : ''
              }`"
              :download="document.title"
              target="_blank"
              >{{ document["title"] }}</a
            >
            <div class="document__description">
              {{ document["description"] }}
            </div>
            <div class="document__date">{{ document["file"] }}</div>

            <div class="document__date">
              {{
                document["date"]
                  ? new Date(document["date"]).toLocaleDateString()
                  : null
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SERVER_ADDRESS } from "@/constants";

export default {
  name: "documents",
  methods: {
    SERVER_ADDRESS() {
      return SERVER_ADDRESS;
    },
    ...mapActions("Documents", {
      addDocuments: "ADD_DOCUMENTS",
    }),
    getFileIcon(fileName) {
      const fileNameSplitted = fileName["serverFilename"].split(".");
      const fileExtension =
        fileNameSplitted[fileNameSplitted.length - 1].length > 3
          ? fileNameSplitted[fileNameSplitted.length - 1].substring(0, 3)
          : fileNameSplitted[fileNameSplitted.length - 1];

      try {
        return require(`./../../assets/file-icons/${fileExtension}.svg`);
      } catch (err) {
        return require(`./../../assets/file-icons/blank.svg`);
      }
    },
  },
  data() {
    return {
      filterText: "",
      fileExtensions: ["7z", "doc", "pdf", "png", "ppt", "txt", "xls", "zip"],
    };
  },
  computed: {
    ...mapGetters("Documents", {
      documents: "getDocs",
    }),
    filteredDocs() {
      if (this.filterText.trim() === "") return this.documents;
      return this.documents.filter((doc) => {
        return doc["title"]
          .toLowerCase()
          .includes(this.filterText.toLocaleLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.form {
  display: flex;
  align-items: center;
}
.form input {
  margin-right: 8px;
  padding: 2px 4px;

  border-radius: 4px;
  background: #f0f0f0;
}
.form button {
  padding: 2px 4px;
  border-radius: 4px;
}
.documents__main {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
}
.documents__container {
  max-height: 80vh;
  margin-bottom: 1rem;
  overflow-y: auto;
}
.document__title {
  position: relative;
  display: inline-block;
  margin: 0 16px 10px 0;

  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  color: #727271;
}

.document__img {
  flex: 0 0 auto;
  display: block;

  width: 64px;
  height: 64px;
}
.document__img img {
  min-height: 100%;
}

@media screen and (max-width: 900px) {
  .document__img {
    height: 48px;
    width: 48px;
  }
}

@media screen and (max-width: 640px) {
  .document__img {
    height: 32px;
    width: 32px;
  }
}
</style>
