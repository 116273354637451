var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content content___inner"},[_c('div',{staticClass:"documents__main"},[_c('h2',[_vm._v("ДОКУМЕНТЫ")]),_c('form',{staticClass:"form",attrs:{"action":""}},[_c('div',{staticClass:"search__title"},[_vm._v("Поиск")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterText),expression:"filterText"}],attrs:{"type":"text","placeholder":""},domProps:{"value":(_vm.filterText)},on:{"input":function($event){if($event.target.composing)return;_vm.filterText=$event.target.value}}})]),_c('div',{staticClass:"documents__container"},_vm._l((_vm.filteredDocs),function(document){return _c('div',{key:document._id,staticClass:"document__wrap"},[_c('a',{staticClass:"document__img",attrs:{"href":`${
            document.documents[0]
              ? `${_vm.SERVER_ADDRESS()}/static/${
                  document.documents[0]['serverFilename']
                }`
              : ''
          }`,"download":document.title,"target":"_blank"}},[_c('img',{attrs:{"src":_vm.getFileIcon(document.documents[0]),"alt":""}})]),_c('div',{staticClass:"document__inner"},[_c('a',{staticClass:"document__title",attrs:{"href":`${
              document.documents[0]
                ? `${_vm.SERVER_ADDRESS()}/static/${
                    document.documents[0]['serverFilename']
                  }`
                : ''
            }`,"download":document.title,"target":"_blank"}},[_vm._v(_vm._s(document["title"]))]),_c('div',{staticClass:"document__description"},[_vm._v(" "+_vm._s(document["description"])+" ")]),_c('div',{staticClass:"document__date"},[_vm._v(_vm._s(document["file"]))]),_c('div',{staticClass:"document__date"},[_vm._v(" "+_vm._s(document["date"] ? new Date(document["date"]).toLocaleDateString() : null)+" ")])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }