<template>
  <section class="content">
    <div
      v-if="
        background['background_video'] &&
        background['background_video'].length > 0
      "
    >
      <video
        class="section__video-bg"
        :autoplay="true"
        :loop="true"
        muted="muted"
      >
        <source
          :src="`${SERVER_ADDRESS()}/static/${
            background['background_video'][0]['serverFilename']
          }`"
          type="video/mp4"
        />
      </video>

      <video
        class="section__video_mb"
        :autoplay="true"
        :loop="true"
        muted="muted"
      >
        <source
          :src="`${SERVER_ADDRESS()}/static/${
            background['background_video'][0]['serverFilename']
          }`"
          type="video/mp4"
        />
      </video>
    </div>

    <div
      v-else-if="
        background['background_image'] &&
        background['background_image'].length > 0
      "
    >
      <img
        class="section__video-bg"
        :src="`${SERVER_ADDRESS()}/static/${
          background['background_image'][0]['serverFilename']
        }`"
        alt="BG"
      />
      <img
        class="section__video_mb"
        :src="`${SERVER_ADDRESS()}/static/${
          background['background_image'][0]['serverFilename']
        }`"
        alt="BG"
      />
    </div>

    <div
      v-else-if="background.length < 1"
      :key="background ? background._id : null"
    >
      <video
        class="section__video-bg"
        :autoplay="true"
        :loop="true"
        muted="muted"
      >
        <source src="../assets/images/main-bg.mp4" type="video/mp4" />
      </video>

      <video
        class="section__video_mb"
        :autoplay="true"
        :loop="true"
        muted="muted"
      >
        <source src="../assets/images/main-bg.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="bg_overlay"></div>

    <swiper
      v-if="news.length"
      class="slider mobile"
      :slides-to-show="1"
      :center-mode="true"
      :dots="false"
    >
      <router-link
        class="slide"
        v-for="article in news"
        :key="article._id"
        :to="{ name: 'News' }"
      >
        <div class="sliderArticle__container">
          <div class="news__text">
            {{ article.title }}
          </div>
          <div class="news__date">
            {{ new Date(article.date).toLocaleDateString() }}
          </div>
        </div>
      </router-link>
    </swiper>

    <div class="news__block_wrap">
      <div class="news__block">
        <h1>Новости</h1>
        <router-link
          class="news__item"
          v-for="article in news"
          :key="article._id"
          :to="{ name: 'News' }"
        >
          <div>
            <div class="news__date">
              {{ new Date(article.date).toLocaleDateString() }}
            </div>
            <div class="news__text">
              {{ article.title }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { SERVER_ADDRESS } from "@/constants";
import { VueAgile } from "vue-agile";

export default {
  name: "Home",
  components: {
    swiper: VueAgile,
  },
  methods: {
    SERVER_ADDRESS() {
      return SERVER_ADDRESS;
    },
    checkBackground() {},
  },
  computed: {
    ...mapGetters("News", {
      news: "getNews",
    }),
    ...mapGetters("Background", {
      background: "getBackground",
    }),
  },
};
</script>
<style lang="scss">
.content {
  position: relative;
}
.bg_overlay {
  position: absolute;
  inset: 0;
  background: hsl(100 100% 100% / 0.25);
}

.sliderArticle__container {
  margin-top: 1rem;
  padding: 8px 48px;
}
.articleDescription {
  padding: 8px;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #727271;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 36px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}

.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  display: block;
  object-fit: cover;
  width: 100vw;
}
</style>
