export default {
  namespaced: true,
  state: {
    news: [],
  },
  getters: {
    getNews: (state) => state.news,
  },
  mutations: {
    addNews: (state, data) => {
      state.news = data;
    },
  },
  actions: {
    ADD_NEWS: ({ commit }, data) => {
      commit("addNews", data);
    },
  },
};
