import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import News from "@/components/news/index.vue";
import Documents from "@/components/documents/index.vue";
import Contact from "@/components/contact/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeNames = {
  Main: "ЦифроТех",
  News: "ЦифроТех. Новости",
  Documents: "ЦифроТех. Документы",
  Contact: "ЦифроТех. Контакты",
};

router.beforeEach((to, from, next) => {
  document.title = routeNames[to.name];
  next();
});
export default router;
