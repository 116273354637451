export default {
  namespaced: true,
  state: {
    contacts: [],
    footerContacts: [],
    visitCard: [],
  },
  getters: {
    getContacts: (state) => state.contacts,
    getFooterContacts: (state) => state.footerContacts,
    getVisitCard: (state) => state.visitCard,
  },
  mutations: {
    addContacts: (state, data) => {
      state.contacts = data;
    },
    addFooterContacts: (state, data) => {
      state.footerContacts = data;
    },
    setVisitCard: (state, data) => {
      state.visitCard = data;
    },
  },
  actions: {
    ADD_CONTACTS: ({ commit }, data) => {
      commit("addContacts", data);
    },
    ADD_FOOTER_CONTACTS: ({ commit }, data) => {
      commit("addFooterContacts", data);
    },
    SET_VISIT_CARD: ({ commit }, data) => {
      commit("setVisitCard", data);
    },
  },
};
