var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[(
      _vm.background['background_video'] &&
      _vm.background['background_video'].length > 0
    )?_c('div',[_c('video',{staticClass:"section__video-bg",attrs:{"autoplay":true,"loop":true,"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":`${_vm.SERVER_ADDRESS()}/static/${
          _vm.background['background_video'][0]['serverFilename']
        }`,"type":"video/mp4"}})]),_c('video',{staticClass:"section__video_mb",attrs:{"autoplay":true,"loop":true,"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":`${_vm.SERVER_ADDRESS()}/static/${
          _vm.background['background_video'][0]['serverFilename']
        }`,"type":"video/mp4"}})])]):(
      _vm.background['background_image'] &&
      _vm.background['background_image'].length > 0
    )?_c('div',[_c('img',{staticClass:"section__video-bg",attrs:{"src":`${_vm.SERVER_ADDRESS()}/static/${
        _vm.background['background_image'][0]['serverFilename']
      }`,"alt":"BG"}}),_c('img',{staticClass:"section__video_mb",attrs:{"src":`${_vm.SERVER_ADDRESS()}/static/${
        _vm.background['background_image'][0]['serverFilename']
      }`,"alt":"BG"}})]):(_vm.background.length < 1)?_c('div',{key:_vm.background ? _vm.background._id : null},[_c('video',{staticClass:"section__video-bg",attrs:{"autoplay":true,"loop":true,"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/images/main-bg.mp4"),"type":"video/mp4"}})]),_c('video',{staticClass:"section__video_mb",attrs:{"autoplay":true,"loop":true,"muted":"muted"},domProps:{"muted":true}},[_c('source',{attrs:{"src":require("../assets/images/main-bg.mp4"),"type":"video/mp4"}})])]):_vm._e(),_c('div',{staticClass:"bg_overlay"}),(_vm.news.length)?_c('swiper',{staticClass:"slider mobile",attrs:{"slides-to-show":1,"center-mode":true,"dots":false}},_vm._l((_vm.news),function(article){return _c('router-link',{key:article._id,staticClass:"slide",attrs:{"to":{ name: 'News' }}},[_c('div',{staticClass:"sliderArticle__container"},[_c('div',{staticClass:"news__text"},[_vm._v(" "+_vm._s(article.title)+" ")]),_c('div',{staticClass:"news__date"},[_vm._v(" "+_vm._s(new Date(article.date).toLocaleDateString())+" ")])])])}),1):_vm._e(),_c('div',{staticClass:"news__block_wrap"},[_c('div',{staticClass:"news__block"},[_c('h1',[_vm._v("Новости")]),_vm._l((_vm.news),function(article){return _c('router-link',{key:article._id,staticClass:"news__item",attrs:{"to":{ name: 'News' }}},[_c('div',[_c('div',{staticClass:"news__date"},[_vm._v(" "+_vm._s(new Date(article.date).toLocaleDateString())+" ")]),_c('div',{staticClass:"news__text"},[_vm._v(" "+_vm._s(article.title)+" ")])])])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }