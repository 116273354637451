<template>
  <section class="content content___inner">
    <div class="news__main">
      <h2>КОНТАКТЫ</h2>
      <div class="contact__wrap">
        <div class="contact__block">
          <div class="contact__body">
            <div
              v-for="(contact, contact_key) in contacts"
              :key="contact_key"
              class="contact__string"
            >
              <b>{{ contact["title"] }}</b
              >&nbsp;<span>{{ contact["description"] }}</span>
            </div>
          </div>

          <div class="contact__title">На карте:</div>

          <div style="position: relative; overflow: hidden" class="map">
            <iframe
              src="https://yandex.uz/map-widget/v1/?from=mapframe&ll=37.505217%2C55.674360&mode=whatshere&whatshere%5Bpoint%5D=37.503007%2C55.674541&whatshere%5Bzoom%5D=17&z=17"
              width="100%"
              height="200"
              style="position: relative"
            >
            </iframe>
          </div>
        </div>

        <div class="contact__block2">
          <div class="contact__inner">
            <div class="contact__title2">Визитная карточка:</div>
            <img
              v-if="visitCard[0]"
              :src="`${SERVER_ADDRESS()}static/${
                visitCard[0]['visitCardImage'][0]['serverFilename']
              }`"
              alt=""
            />
            <img v-else src="../../assets/images/qode.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SERVER_ADDRESS } from "@/constants";

export default {
  name: "contact",
  methods: {
    SERVER_ADDRESS() {
      return SERVER_ADDRESS;
    },
    ...mapActions("Contacts", {
      addContacts: "ADD_CONTACTS",
    }),
  },
  computed: {
    ...mapGetters("Contacts", {
      contacts: "getContacts",
      visitCard: "getVisitCard",
    }),
  },
};
</script>

<style scoped>
.contact__string {
  margin-bottom: 6px;
  font-size: 1.1rem;
}
.contact__string:last-child {
  margin-bottom: 0;
}
</style>
