<template>
  <div id="app">
    <header>
      <div class="head__wrap">
        <div class="head__block">
          <div class="logo">
            <router-link to="/"
              ><img src="./assets/images/logo.svg" alt=""
            /></router-link>
          </div>
          <!-- <div class="slogan">Открытые <br> Цифровые <br> Технологии</div> -->
        </div>
        <div class="head__block2">
          <ul class="menu__top">
            <li>
              <router-link :to="{ name: 'News' }">
                <span>О НАС</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'Documents' }">
                <span>ДОКУМЕНТЫ</span>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'Contact' }">
                <span>КОНТАКТЫ</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </header>

    <router-view />

    <footer>
      <div class="footer__wrap">
        <div class="footer__block">
          <div class="logo__bottom">
            <img src="./assets/images/logo-bottom.svg" alt="" />
            <div class="footer_info_wrapper">
              <div
                class="footer_info_string"
                v-for="footerContact in footerContacts.length === 0
                  ? footerContactsDefault
                  : footerContacts"
                :key="footerContact['contactString']"
              >
                {{ footerContact["contactString"] }}
              </div>
            </div>
          </div>
        </div>
        <div class="footer__block"></div>
        <div class="footer__block">
          <div class="telecom__wrap">
            <div class="telecom__img">
              <img src="./assets/images/logo-telecom.png" alt="" />
            </div>
            <span>
              Группа <br />
              Компании
            </span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { apiConfig } from "@/configs/api.config";
import { mapActions, mapGetters } from "vuex";

export default {
  mounted() {
    if (this.news.length === 0) {
      axios
        .get(apiConfig.url + "get-data")
        .then(({ data }) => {
          if (data) {
            console.log(data);

            this.addNews(data["documentations"]);
            this.addDocuments(data["articles"]);
            this.addContacts(data["contacts"]);
            this.addFooterContacts(data["footerContacts"]);
            this.setVisitCard(data["visitCard"]);

            if (data["background"].length)
              this.setBackground(
                data["background"][data["background"].length - 1]
              );
          }
        })
        .catch((err) => {
          if (err) throw new Error(err);
        });
    }
  },
  methods: {
    ...mapActions("News", {
      addNews: "ADD_NEWS",
    }),
    ...mapActions("Documents", {
      addDocuments: "ADD_DOCUMENTS",
    }),
    ...mapActions("Contacts", {
      addContacts: "ADD_CONTACTS",
      addFooterContacts: "ADD_FOOTER_CONTACTS",
      setVisitCard: "SET_VISIT_CARD",
    }),
    ...mapActions("Background", {
      setBackground: "SET_BACKGROUND",
    }),
  },
  computed: {
    ...mapGetters("News", {
      news: "getNews",
    }),
    ...mapGetters("Contacts", {
      footerContacts: "getFooterContacts",
    }),
    footerContactsDefault() {
      return [
        { contactString: "ООО «ЦифроТех»" },
        { contactString: "E-mail: info@cifro.tech" },
        { contactString: "Телефон: +7 (499) 673-39-90" },
      ];
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: #727271;
  font-size: 16px;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  /* background: #ebecec; */
  /* height: 100vh; */
}

a {
  text-decoration: none;
  color: #727271;
}
a:hover {
  color: #727271;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

button {
  border: none;
  background: none;
  box-shadow: unset;
}

button:focus {
  outline: none;
  box-shadow: unset;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1440px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

header {
  padding: 10px 60px 10px 60px;
  background: #fff;
  border-bottom: 3px solid #bdbebe;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.logo a img {
  width: 210px;
}

.head__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.head__block {
  display: flex;
  align-items: center;
}

.slogan {
  border-left: 2px solid #979799;
  margin: 19px 0 0 15px;
  padding: 0 0 0 15px;
  color: #979799;
  text-transform: uppercase;
  /* font-weight: bold; */
  font-size: 14px;
}

.head__block2 {
}

.menu__top {
  display: flex;
}

.menu__top li {
  margin: 0 0 0 35px;
}

.menu__top li a {
  text-decoration: none;
  outline-offset: 8px;
}

.menu__top li a:hover {
  opacity: 0.9;
  transition: 0.3s;
}

.content {
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 101px 0 120px 0;
}

.news__block_wrap {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

.news__block {
  width: 600px;
  overflow-y: scroll;
  height: 100%;
  //margin: 0 15px 0 0;
  background: url(./assets/images/gradient.svg) 0 0 repeat-y;
  position: relative;
  padding: 0 0 0 255px;
}

.news__block a {
  text-decoration: none;
}

.news__block a:hover {
  opacity: 0.9;
  transition: 0.3s;
}

.news__block::-webkit-scrollbar {
  width: 9px;
  background: #eeeeee;
  border-radius: 50px;
  box-shadow: inset 0 0 0 1px #eeeeee;
}

.news__block::-webkit-scrollbar-thumb {
  background: #bdbebe;
  border-radius: 50px;
  position: relative;
}

h1 {
  font-size: 32px;
  margin: 20px 0 20px 0;
}

.news__date {
  font-weight: bold;
  font-size: 19px;
  margin: 0 0 10px 0;
}

.news__text {
  font-weight: bold;
  margin: 0 15px 33px 0;
}

footer {
  padding: 30px 60px 30px 60px;
  background: #ebecec;
  border-top: 3px solid #bdbebe;
  position: absolute;
  /* width: 100%; */
  bottom: 0;
  /* position: relative; */
  z-index: 1;
  width: 100%;
}

.logo__bottom {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.logo__bottom img {
  width: 60px;
  margin: 0 30px 0 0;
}

.logo__bottom span a {
  text-decoration: none;
}
.footer_info_wrapper {
  height: 100%;
}
.footer_info_string {
  margin-bottom: 6px;
}
.footer_info_string:last-child {
  margin-bottom: 0;
}

.section__video-bg {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.news__main {
  padding: 0 60px;
  overflow-y: scroll;
  height: 100%;
}

.news__main::-webkit-scrollbar {
  width: 9px;
  background: #eeeeee;
  border-radius: 50px;
  box-shadow: inset 0 0 0 1px #eeeeee;
}

.news__main::-webkit-scrollbar-thumb {
  background: #bdbebe;
  border-radius: 50px;
  position: relative;
}

h2 {
  font-size: 23px;
  text-transform: uppercase;
  margin: 30px 0 30px 0;
}

.news__img {
  width: 25%;
}

.news__inner {
  width: 75%;
}

.news__wrap {
  width: 100%;
  margin: 0 0 50px 0;
}

.news__inner {
  margin: 0 0 0 40px;
}

.news__date {
  font-size: 0.95rem;
  font-weight: normal;
}

.news__title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px 0;
  position: relative;
  color: #727271;
}
.document__title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px 0;
  position: relative;
  color: #727271;
}

.news__description {
}

.content___inner {
  height: 100vh;
  padding: 101px 0 150px 0;
}

.form {
  display: flex;
  margin: 0 0 60px 0;
}

.search__title {
  font-weight: bold;
  margin: 0 10px 0 0;
}

.form button {
  cursor: pointer;
  background: #ebecec;
  border: 1px solid #bdbebe;
  margin: 0 0 0 -1px;
}

.document__date {
  font-size: 13px;
  margin: 5px 0 0 0;
}

.contact__wrap {
  display: flex;
}

.contact__block {
  width: 60%;
}

.contact__block2 {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.contact__title {
  font-weight: bold;
  margin: 0 0 20px 0;
}

.contact__title2 {
  font-weight: bold;
  font-size: 22px;
  margin: 0 0 20px 0;
}

.contact__body {
  margin: 0 0 90px 0;
}

.map {
  margin: 0 0 100px 0;
}

.contact__inner {
  width: 400px;
  text-align: center;
}

.document__wrap {
  display: flex;
  margin: 0 0 30px 0;
}

.document__inner {
  margin: 0 0 0 30px;
}

.footer__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timing__img img {
  width: 150px;
}

.telecom__wrap {
  display: flex;
  align-items: center;
  margin: 0 0 0 -150px;
}

.telecom__wrap span {
  padding: 0 0 0 15px;
  font-weight: bold;
  border-left: 1px solid #727271;
  display: block;
  margin: 0 0 -3px 5px;
}

.section__video_mb {
  display: none;
}

.slider {
  display: none !important;
}

.contact__inner img {
  margin: 0 0 30px 0;
}

.footer__inner {
}

.news__body {
  display: grid;
  grid-template-rows: 0fr;
  font-size: 15px;
  line-height: 21px;
  margin: 0 0 8px 0;
  padding: 1rem 0;

  transition: grid-template-rows 192ms;
}
.news__body[data-show="true"] {
  grid-template-rows: 1fr;
}
.news__body[data-show="true"] svg {
  transform: rotate(90deg);
}
.news__body > div {
  overflow: hidden;
}

.accordion5 {
  width: 70%;
  margin: 0 0 0 0;
}

.fag-accordion {
  padding: 0 0 0 0;
}

.accordion5 .link3 {
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-size: 18px;
  line-height: 33px;
  position: relative;
}

.accordion5 .link3 p {
  padding: 0 0 0 0;
  width: 95%;
  margin: 0;
  //font-weight: bold;
  color: #727271;
  line-height: 26px;
}

.accordion5 .link3 svg {
  margin: 0 0 0 19px;
  position: absolute;
  bottom: 11px;
  /* left: 0; */
}
.news__title svg {
  --animation_duration: 192ms;
  -webkit-transition: all 92ms ease;
  -o-transition: all 92ms ease;
  transition: all 92ms ease;

  fill: #333333;
  fill-opacity: 0.3;
}
.news__title:hover svg {
  fill-opacity: 0.8;
}
.news__title svg[data-show="true"] {
  transform: rotate(180deg);
}

.accordion5 li:last-child .link3 {
  border-bottom: 0;
}

.accordion5 li {
  padding: 15px 0;
}

.accordion-title p {
  margin: -10px 0 0 0;
  font-size: 15px;
  line-height: 21px;
  padding: 7px 0;
}

.accordion-title {
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: none;
}

.acc__wrap {
  display: flex;
}

.acc__inner {
  width: 75%;
  margin: 0 0 0 30px;
}
@media screen and (max-width: 1300px) {
  .news__block {
    width: 360px;
    overflow-y: scroll;
    //margin: 0 15px 0 0;
    //background: #fff;
    padding: 0 0 0 25px;
    height: 100%;
  }

  .content {
    height: 100vh;
    position: relative;
    overflow: hidden;
    padding: 101px 0 0 0;
  }

  .content___inner {
    height: 100vh;
    padding: 101px 0 150px 0;
  }
}
/* стили для md-устройств */
@media screen and (max-width: 1200px) {
  .news__wrap {
    width: 100%;
    margin: 0 0 50px 0;
  }

  .accordion5 {
    width: 100%;
    margin: 0 0 0 0;
  }
}

/* стили для sm-устройств */
@media screen and (max-width: 1025px) {
  .acc__inner {
    width: 100%;
    margin: 0 0 0 30px;
  }

  .news__block {
    width: 360px;
    overflow-y: scroll;
    //margin: 0 15px 0 0;
    //background: #fff;
    padding: 0 0 130px 25px;
    height: 100%;
  }

  .section__video-bg {
    display: none;
  }

  .content {
    background: url(./assets/images/main-bg.png) 0 0 no-repeat;
    background-size: cover;
  }

  .content___inner {
    background: none;
  }

  .telecom__wrap {
    display: flex;
    align-items: center;
    margin: 0 0 0 0;
  }
}

/* стили для sm-устройств */
@media screen and (max-width: 992px) {
  .content___inner {
    background: none;
  }

  .news__block {
    width: 360px;
    overflow-y: scroll;
    //margin: 0 15px 0 0;
    background: #fff;
    padding: 0 0 0 25px;
    height: 1224px;
  }
}

@media screen and (max-width: 900px) {
  .footer__wrap {
    display: block;
  }

  .timing__img {
    margin: 20px 0 0 0;
  }

  .telecom__img {
    margin: 0 0 0 -13px;
  }

  .telecom__wrap {
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
  }

  .footer__block {
    display: flex;
    justify-content: center;
  }
}

/* Ipad*/
@media screen and (max-width: 825px) {
  .news__wrap {
    width: 100%;
  }

  footer {
    position: relative;
  }

  .news__block {
    width: 360px;
    overflow-y: scroll;
    //margin: 0 15px 0 0;
    background: #fff;
    padding: 0 0 0 25px;
    height: 100%;
  }

  .news__block_wrap {
    display: flex;
    justify-content: flex-end;
    height: 905px;
  }

  .content {
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 0 0 0;
  }

  header {
    position: relative;
  }
  .menu__top {
    display: flex;
    justify-content: center;
    margin: 20px 0 10px 0;
  }

  .menu__top li {
    margin: 0 20px 0 35px;
  }

  .head__wrap {
    display: block;
    align-items: center;
    justify-content: space-between;
  }

  .head__block {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .contact__wrap {
    display: block;
  }

  .contact__block {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .contact__block2 {
    width: 100%;
    margin: 0 0 0 0;
  }

  .contact__inner {
    width: 100%;
  }
}

/* Ipad mini*/
@media screen and (max-width: 640px) {
  .content {
    background: none;
  }

  .news__block_wrap {
    display: none;
  }

  .section__video_mb {
    display: block;
  }

  .section__video_mb {
    width: 100%;
  }

  .slider {
    display: block !important;
    width: 100%;
  }

  .news__wrap {
    display: block;
  }

  .news__inner {
    margin: 18px 0 0 0;
    width: 100%;
  }

  .news__img {
    width: 35%;
  }

  .news__main {
    padding: 0 20px;
    overflow-y: unset;
    height: 100%;
  }

  header {
    padding: 10px 20px 10px 20px;
  }

  footer {
    padding: 30px 30px 30px 20px;
  }

  .acc__wrap {
    display: block;
  }

  .acc__inner {
    width: 100%;
    margin: 30px 0 0 0;
  }

  .news__date {
  }
  .news__title {
    font-size: 16px;
    font-weight: 600;
    margin: 0 16px 10px 0;
    position: relative;
    color: #727271;
    line-height: 21px;
    display: flex;
    justify-content: flex-end;
  }

  .accordion5 .link3 svg {
    margin: 0 0 0 19px;
    position: absolute;
    top: 5px;
    right: -24px;
    /* left: 0; */
  }
}

/* Galaxy S8+*/
@media screen and (max-width: 480px) {
  .news__text {
    font-weight: bold;
    margin: 0 0 8px 0;
    font-size: 15px;
  }

  .menu__top li {
    margin: 0 10px 0 10px;
  }

  .news__block {
    width: 360px;
    overflow-y: scroll;
    height: 570px;
    margin: 0 15px 0 0;
    background: #fff;
    padding: 0 0 0 25px;
  }

  .news__block {
    width: 360px;
    overflow-y: scroll;
    margin: 0 15px 0 0;
    background: #fff;
    padding: 0 0 0 25px;
    height: 100%;
  }
}

/* Galaxy*/
@media screen and (max-width: 415px) {
}

/* Iphone 6.7.8 */
@media screen and (max-width: 375px) {
  .news__text {
    font-weight: bold;
    //margin: 0 15px 33px 0;
    font-size: 13px;
  }
}

/* Iphone 5.5SE */
@media screen and (max-width: 320px) {
}
</style>
